// @flow

import React from 'react';
import type {ChildrenArray, Element, Node} from 'react';
import {
  Image,
  ImageBackground,
  Pressable,
  StyleSheet,
  Text,
  View,
} from 'react-native';

import { useFonts } from 'expo-font';
// import { BowlbyOneSC_400Regular } from '@expo-google-fonts/bowlby-one-sc';
import type {ViewStyleProp} from 'react-native/Libraries/StyleSheet/StyleSheet';

type ItemProps = {
  children: string,
  onPress?: () => void
}

const Item: (props: ItemProps) => Node = (props: ItemProps) => {
  return (
    <Text style={styles.item}
          onPress={props.onPress}>
      {props.children}
    </Text>
  );
}

type MenuBarProps = {
  children: ChildrenArray<Element<typeof Item>>,
};

function MenuBar(props: MenuBarProps): Node {
  // let [fontsLoaded: boolean] = useFonts({
  //   BowlbyOneSC_400Regular
  // });

  return (
    <ImageBackground style={styles.menuBar}
                     source={require('./assets/papers/white.webp')}
                     resizeMode='repeat'>
      <Image style={styles.svg}
             source={require('./assets/svg/logo2.svg')} />
      <View style={styles.itemsContainer}>
        {props.children}
      </View>
    </ImageBackground>
  );
}
MenuBar.Item = Item;


const styles = StyleSheet.create({
  menuBar: {
    // backgroundColor: 'lemonchiffon',
    flexDirection: 'row',
    width: '100%',
    height: 90,
    // maxWidth: 500,
    paddingHorizontal: 10,
    paddingVertical: 5,
    // marginBottom: 10,
    shadowOpacity: 0.4,
    shadowColor: 'grey',
    shadowRadius: 2,
    shadowOffset: { width: 0, height: 2 },
    zIndex: 1
  },
  itemsContainer: {
    flex: 1,
    flexDirection: 'row',
    marginLeft: 10,
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
  },
  item: {
    fontFamily: 'CarterOne_400Regular',
    fontSize: 24,
    // color: '#3B64A1',
    color: '#2B4F83',
    marginLeft: 20
  },
  svg: {
    aspectRatio: 1,
    height: '100%',
  }
});

export default MenuBar;

// @flow

import React from 'react';
import type {Node} from 'react';
import {
  Image,
  StyleSheet,
  View
} from 'react-native';

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/modules/autoplay/autoplay.min.css";
import "swiper/modules/effect-fade/effect-fade.min.css";

// import required modules
import { Autoplay, EffectFade } from "swiper";

import "./styles.css";
import type {ViewProps} from "react-native/Libraries/Components/View/ViewPropTypes";

const TabletSlider: (props: ViewProps) => Node = (props: ViewProps) => {

  return (
    <View {...props}>
      <Swiper speed={1000}
              allowTouchMove={false}
              autoplay={{
                delay: 1500,
                disableOnInteraction: false,
              }}
              effect={"fade"}
              preloadImages={false}
              modules={[Autoplay, EffectFade]}
              className="mySwiper">
        <SwiperSlide>
          <Image style={styles.image}
                 source={require('./assets/screenshots/circ1.webp')}/>
        </SwiperSlide>
        <SwiperSlide>
          <Image style={styles.image}
                 source={require('./assets/screenshots/circ2.webp')}/>
        </SwiperSlide>
        <SwiperSlide>
          <Image style={styles.image}
                 source={require('./assets/screenshots/circ3.webp')}/>
        </SwiperSlide>
        <SwiperSlide>
          <Image style={styles.image}
                 source={require('./assets/screenshots/circ4.webp')}/>
        </SwiperSlide>
        <SwiperSlide>
          <Image style={styles.image}
                 source={require('./assets/screenshots/circ5.webp')}/>
        </SwiperSlide>
        <SwiperSlide>
          <Image style={styles.image}
                 source={require('./assets/screenshots/circ6.webp')}/>
        </SwiperSlide>
        <SwiperSlide>
          <Image style={styles.image}
                 source={require('./assets/screenshots/circ7.webp')}/>
        </SwiperSlide>
      </Swiper>
    </View>
  );
}

const styles = StyleSheet.create({
  image: {
    width: '100%',
    height: '100%'
  }
});

export default TabletSlider;

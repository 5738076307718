// @flow

import React, {useImperativeHandle, useRef, useState} from "react";
import type {Node} from 'react';
import {
  Image,
  ImageBackground,
  StyleSheet,
  Text,
  View
} from 'react-native';
import { Textfit } from 'react-textfit';
import TabletSlider from './TabletSlider';
import Screenshots from './Screenshots';

import "./styles.css";


const Page: React$AbstractComponent<mixed, mixed> = React.forwardRef((props, ref) => {
  // const upTo1000Ref = useRef(null);
  // const ovalRoundRef = useRef(null);
  const screenshotsRef = useRef(null);

  useImperativeHandle(ref, () => ({
    // get upTo1000() {
    //   return upTo1000Ref.current;
    // },
    // get ovalRound() {
    //   return ovalRoundRef.current;
    // },
    get screenshots() {
      return screenshotsRef.current;
    },
  }));

  return (
    <>
      <ImageBackground style={styles.paper}
                       source={require('./assets/papers/white.webp')}
                       resizeMode='repeat'>
        <View style={styles.section1}>
          <View style={styles.left}>
            <View style={styles.newLiner}/>
            <div className='text-container'>
              <Textfit mode="single"
                       max={56}>
                Fine Puzzles
              </Textfit>
            </div>
            <div className='text-container'>
              <Textfit mode="single"
                       max={40}>
                Real jigsaw puzzles<br/>inside an app!
              </Textfit>
            </div>
          </View>
          <View style={styles.right}>
            <View style={styles.tabletContainer}>
              <ImageBackground style={styles.tablet}
                               source={require('./assets/svg/tablet.svg')}>
                <TabletSlider style={styles.tabletImage} />
              </ImageBackground>
            </View>
          </View>
        </View>
      </ImageBackground>
      <ImageBackground style={styles.paper}
                       source={require('./assets/papers/yellow.webp')}
                       resizeMode='repeat'>
        <View style={styles.section2}>
          <View style={styles.left}>
            <View style={styles.newLiner}/>
            <div className='text-container'>
              <Textfit mode="single"
                       max={56}>
                Beautiful images
              </Textfit>
            </div>
          </View>
          <View style={styles.right}>
            <Image style={styles.images}
                   source={require('./assets/images/imgs.webp')} />
          </View>
        </View>
      </ImageBackground>
      <ImageBackground style={styles.paper}
                       source={require('./assets/papers/white.webp')}
                       resizeMode='repeat'>
        <View style={styles.section1}> 
          <View style={styles.left}>
            <View style={styles.newLiner}/>
            <div className='text-container'>
              <Textfit mode="single"
                       max={56}>
                Up to<br/>1000 pieces
              </Textfit>
            </div>
          </View>
          <View style={styles.right}>
            <Image style={styles.big}
                   source={require('./assets/images/big.webp')} />
          </View>
        </View>
      </ImageBackground>
      <ImageBackground style={styles.paper}
                       source={require('./assets/papers/blue.webp')}
                       resizeMode='repeat'>
        <View style={styles.section2}>
          <View style={styles.left}>
            <View style={styles.newLiner}/>
            <div className='text-container'>
              <Textfit mode="single"
                       max={56}>
                Oval and<br/>circular puzzles
              </Textfit>
            </div>
          </View>
          <View style={styles.right}>
            <Image style={styles.ovalRound}
                   source={require('./assets/images/oc.webp')} />
          </View>
        </View>
      </ImageBackground>
      <ImageBackground style={styles.paper}
                       source={require('./assets/papers/white.webp')}
                       resizeMode='repeat'
                       ref={screenshotsRef}>
        <div className='text-container'>
          <Textfit mode="single"
                   max={56}>
            Screenshots
          </Textfit>
        </div>
        <Screenshots style={styles.screenshots}/>
      </ImageBackground>
    </>
  );
});

const styles = StyleSheet.create({
  paper: {
    width: '100%',
    minHeight: '20%',
    paddingVertical: 20,
    // justifyContent: 'center'
  },
  section1: {
    // backgroundColor: 'gold',
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  left: {
    // backgroundColor: 'red',
    flexGrow: 1,
    minWidth: '50%',
    maxWidth: '100%',
    alignItems: 'center',
  },
  right: {
    flexGrow: 1,
    flexBasis: '50%',
    maxWidth: '80%',
  },
  newLiner: {
    // backgroundColor: 'red',
    height: 10,
    minWidth: 440
  },
  tabletContainer: {
    // backgroundColor: 'gold',
    aspectRatio: 1270 / 920,
  },
  tablet: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  tabletImage: {
    height: '83.4782608695652%',
    aspectRatio: 1024 / 768
  },
  section2: {
    width: '100%',
    flexDirection: 'row-reverse',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  images: {
    aspectRatio: 1120 / 758,
  },
  big: {
    // backgroundColor: 'green',
    aspectRatio: 1200 / 792,
  },
  ovalRound: {
    aspectRatio: 1120 / 500,
    // marginHorizontal: '5%',
  },
  section3: {
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'space-evenly'
  },
  textWrapper: {
    borderColor: '#bcbcbc',
    borderRadius: 10,
    width: '80%',
    margin: 16,
    height: 200,
    borderWidth: 2,
  },
  section4: {
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'center'
  },
  screenshots: {
    width: '100%',
    paddingHorizontal: '25%',
    overflow: 'hidden'
  }
});

export default Page;

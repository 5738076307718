// @flow

import React from 'react';
import {useState} from "react";
import type {Node} from 'react';
import {Platform, StyleSheet, View, Text} from "react-native";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/modules/navigation/navigation.min.css";
import "swiper/modules/effect-creative/effect-creative.min.css";

// import required modules
import { EffectCreative, Navigation } from "swiper";

// $FlowIgnore
import Zoom from './react-medium-image-zoom/source';
import './react-medium-image-zoom/source/styles.css';

import "./styles.css";
import type {ViewProps} from "react-native/Libraries/Components/View/ViewPropTypes";

import { Textfit } from 'react-textfit';

const LIST = [
  {
    src: require('./assets/screenshots/50pcs.webp'),
    text: '50 pieces'
  },
  {
    src: require('./assets/screenshots/70pcs.webp'),
    text: '70 pieces'
  },
  {
    src: require('./assets/screenshots/100pcs.webp'),
    text: '100 pieces'
  },
  {
    src: require('./assets/screenshots/160pcs.webp'),
    text: '160 pieces'
  },
  {
    src: require('./assets/screenshots/250pcs.webp'),
    text: '250 pieces'
  },
  {
    src: require('./assets/screenshots/300pcs.webp'),
    text: '300 pieces'
  },
  {
    src: require('./assets/screenshots/400pcs.webp'),
    text: '400 pieces'
  },
  {
    src: require('./assets/screenshots/500pcs.webp'),
    text: '500 pieces'
  },
  {
    src: require('./assets/screenshots/circ3.webp'),
    text: 'Circular puzzle'
  },
  {
    src: require('./assets/screenshots/oval.webp'),
    text: 'Oval puzzle'
  },
  {
    src: require('./assets/screenshots/750pcs.webp'),
    text: '750 pieces'
  },
  {
    src: require('./assets/screenshots/1000pcs.webp'),
    text: '1000 pieces'
  }
];

type SlideProps = {
  src: number,
  text: string,
  hasShadow: boolean
}

const Card: (props: SlideProps) => Node = (props: SlideProps) => {
  const [cardWidth, setCardWidth] = useState(0);

  const onCardLayout = ({layout}) => {
    setCardWidth(layout.width);
  }

  const boxShadow = props.hasShadow ? Platform.select({
    web: {
      boxShadow: `0 ${cardWidth / 288}px ${cardWidth / 72}px 0 rgba(0, 0, 0, 0.10), 0 ${cardWidth / 144}px ${cardWidth / 28}px 0 rgba(0, 0, 0, 0.10)`
    }
  }) : {};

  const fontSize = cardWidth / 8 * 0.5;

  return (
    <View style={[styles.card, boxShadow]}
          onLayout={({nativeEvent}) => onCardLayout(nativeEvent)}>
      <Zoom wrapStyle={{display: 'flex'}}>
        <img src={props.src}
             className="zoomable-img"/>
      </Zoom>
      <View style={styles.footer}>
        <Text style={[styles.text, {fontSize: fontSize}]}>{props.text}</Text>
      </View>
    </View>
  );
}

const Screenshots: (props: ViewProps) => Node = (props: ViewProps) => {

  const [currentIndex, setCurrentIndex] = useState(3);
  const [visibility, setVisibility] = useState(0);

  const slides = LIST.map(({src, text}, index) => {

    return (
      <SwiperSlide key={index}
                  virtualIndex={index}>
        <Card src={src}
              text={text}
              hasShadow={currentIndex - index < 4} />
      </SwiperSlide>
    );
  });

  return (
    <View {...props}>
      <Swiper style={{opacity: visibility}}
              effect={"creative"}
              creativeEffect={{
                limitProgress: 2,
                progressMultiplier: 0.5,
                prev: {
                  translate: ["-10%", 0, 0],
                  rotate: [0, 0, -4],
                },
                next: {
                  translate: ["300%", 0, 0],
                },
              }}
              navigation={{
                disabledClass: 'button-disabled',
                prevEl: '.button-prev',
                nextEl: '.button-next',
              }}
              modules={[EffectCreative, Navigation]}
              initialSlide={currentIndex}
              onActiveIndexChange={(ev) => setCurrentIndex(ev.activeIndex)}
              preloadImages={true}
              onImagesReady={() => setVisibility(1)}
              className="mySwiper">
        {slides}
        <div slot="wrapper-start" className="button-prev"><div className="swiper-button-prev custom"/></div>
        <div slot="wrapper-end" className="button-next"><div className="swiper-button-next custom" /></div>
      </Swiper>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    aspectRatio: 4 / 3,
    maxHeight: 384,
    marginVertical: 40,
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'stretch',
  },
  card: {
    margin: '8%',
  },
  footer: {
    backgroundColor: '#F6F4F0',
    width: '100%',
    aspectRatio: 400 / 50,
    justifyContent: 'center'
    
  },
  text : {
    fontFamily: 'CarterOne_400Regular',
    color: '#2B4F83',
    textAlign: 'center',
  },
});

export default Screenshots;

// @flow

import React, {useEffect, useRef, useState} from 'react';
import type {Node} from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { useFonts } from 'expo-font';
// import { BowlbyOneSC_400Regular } from '@expo-google-fonts/bowlby-one-sc';
// import { RammettoOne_400Regular } from '@expo-google-fonts/rammetto-one';
import { CarterOne_400Regular } from '@expo-google-fonts/carter-one';
import MenuBar from "./MenuBar";
import Page from './Page';

const PRELOAD_IMAGES = [
  require('./assets/papers/white.webp'),
  require('./assets/papers/yellow.webp'),
  require('./assets/papers/blue.webp'),
  require('./assets/svg/logo2.svg'),
  require('./assets/svg/tablet.svg'),
  require('./assets/screenshots/circ1.webp')
];


// const image = new Image();
// image.src = require('./assets/papers/white.webp');

const App: () => Node = () => {
  const [imgsLoaded, setImgsLoaded] = useState(false);

  useEffect(() => {
    // document.title = "Fine Puzzles";

    const loadImage = image => {
      return new Promise((resolve, reject) => {
        const loadImg = new Image()
        loadImg.src = image;

        loadImg.onload = () => resolve(image);
        loadImg.onerror = () => resolve(image);
      })
    }

    Promise.all(PRELOAD_IMAGES.map(image => loadImage(image)))
      .then(() => setImgsLoaded(true));
      // .catch(err => console.log("Failed to load images", err))
    console.log('USE EFFECT');
  }, []);

  const ref: React$ElementRef<any> = useRef(null);

  const [fontsLoaded] = useFonts({
    // BowlbyOneSC_400Regular,
    CarterOne_400Regular
    // RammettoOne_400Regular
  });

  // const preload = `<link rel="preload" as="image" href="${require('./assets/papers/white.webp')}"/>`
  // const preload = require('./assets/papers/white.webp');


  if (!fontsLoaded || !imgsLoaded) {
    return null;
  }

  return (
    <View style={styles.container}>
      <MenuBar>
        {/* <MenuBar.Item onPress={() => console.log('Home')}>Home</MenuBar.Item> */}
        <MenuBar.Item onPress={() => ref.current.screenshots.scrollIntoView()}>Screenshots</MenuBar.Item>
        {/* <MenuBar.Item onPress={() => ref.current.ovalRound.scrollIntoView()}>Contacts</MenuBar.Item> */}
      </MenuBar>
      <Page ref={ref} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#eee',
    alignItems: 'center',
  },
});

export default App;
